import { template as template_ce1e634a68ae4d6394d7a7e3d104e854 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_ce1e634a68ae4d6394d7a7e3d104e854(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
