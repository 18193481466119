import { template as template_ccc1bafaf8a14c30a8de622f06d7ff5d } from "@ember/template-compiler";
const WelcomeHeader = template_ccc1bafaf8a14c30a8de622f06d7ff5d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
