import { template as template_bfa6981d8f6e4c668f240949b2241401 } from "@ember/template-compiler";
const FKLabel = template_bfa6981d8f6e4c668f240949b2241401(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
